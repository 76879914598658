const menuItems = [
	{
		title: "Home",
		url: "#",
		cName: "nav-links",
	},
	{
		title: "How It Works",
		url: "/how",
		cName: "nav-links",
	},
	{
		title: "Pricing",
		url: "/pricing",
		cName: "nav-links",
	},
	{
		title: "About Us",
		url: "#",
		cName: "nav-links",
	},
	{
		title: "History",
		url: "#",
		cName: "nav-links",
	},
	// {
	//   title: "History",
	//   url: "#",
	//   cName: "nav-links-mobile",
	// },
];

export default menuItems;
