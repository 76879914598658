import React from "react";
import { Form, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
const PageTwo = ({ onButtonClick }) => {
  return (
    <section className="aboutCover-container">
      <h2>Job Description</h2>
      <Form className="about-form">
        <div className="description-list">
          <h3>The Job Description</h3>
          <ul>
            <li>Let us know about the job you're applying for</li>
            <li>Requirements</li>
            <li>Let us know about the job you're applying for</li>
            <li>Responsibilities</li>
            <li>Copy & paste from LinkedIn, Glassdoor, etc</li>
          </ul>
          <p>Don't show this message again</p>
        </div>
        <Form.Group className="aboutName" controlId="formBasicEmail">
          <Form.Label>The job description you're applying for</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder=""
            defaultValue=""
            //   className="profile-input"
          />
        </Form.Group>
        <Form.Group className="description-buttons" controlId="formBasicEmail">
          <Button
            variant="primary"
            type="submit"
            className="description-btn"
            onClick={() => onButtonClick("pageone")}
          >
            Previous
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="description-btn"
            onClick={() => onButtonClick("pagethree")}
          >
            Next
          </Button>
        </Form.Group>
      </Form>
    </section>
  );
};

export default PageTwo;
