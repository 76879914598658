import React from "react";
import { Form, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
const PageOne = ({ onButtonClick }) => {
	return (
		<section className="aboutCover-container">
			<h2>About You</h2>

			<Form className="about-form">
				<div className="description-list">
					<h3>The About You</h3>
					<ul>
						<li>Let us know about the job you're applying for</li>
						<li>Requirements</li>
						<li>Let us know about the job you're applying for</li>
						<li>Responsibilities</li>
						<li>Copy & paste from LinkedIn, Glassdoor, etc</li>
					</ul>
					<p>Don't show this message again</p>
				</div>

				<Form.Group className="aboutName" controlId="formBasicEmail">
					<Form.Label>A brief introduction about you</Form.Label>
					<Form.Control
						maxLength={10}
						required
						as="textarea"
						placeholder=""
						defaultValue=""
						//   className="profile-input"
					/>
				</Form.Group>
				<Form.Group
					className=""
					controlId="formBasicEmail"
					onClick={() => onButtonClick("pagetwo")}>
					<Button
						variant="primary"
						type="submit"
						className="about-btn">
						Next
					</Button>
				</Form.Group>
			</Form>
		</section>
	);
};

export default PageOne;
