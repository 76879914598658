import React from "react";
import Facebook from "../../icons/Facebook.svg"
import Tiktok from "../../icons/Tiktok.svg"
import Twitter from "../../icons/Twitter.svg"
import {Row , Col} from "react-bootstrap"
const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <Row>
          <Col lg={5}>
            <img src="/assets/Logo.png" className="footer-logo" />
          </Col>
          <Col lg={2}>
            <h1>Company</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">How it Works</a>
              </li>
              <li>
                <a href="/">Pricing</a>
              </li>
              <li>
                <a href="/">About</a>
              </li>
            </ul>
          </Col>
          <Col lg={2}>
            <h1>Resources</h1>
            <ul>
              <li>
                <a href="/">About Cover Letters</a>
              </li>
            </ul>
          </Col>
          <Col lg={3}>
  
              <h1>About</h1>
              <ul>
                <li>
                  <a href="/">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/">Privacy policy</a>
                </li>
              </ul>
              <div className="icons">
                <img src={Twitter} alt="" />
                <img src={Tiktok} alt="" />
                <img src={Facebook} alt="" />
              </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
